/* eslint-disable no-undef */
/* eslint-disable no-return-assign */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
import React, {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import cn from 'classnames';
import { useFragment, useQuery } from '@apollo/client';

import Rub from 'app/components/ui/Rub';
import { useDishDetails } from 'app/components/MobileDishDetails/hooks/useDishDetails';
import stubSrc from 'app/components/DishImage/stub-image/stub-m.jpg';

import { useDishCustoms } from 'app/views/Hooks/useDishCustoms';
import { CustomIngredientsSelectDishRow } from 'app/views/Menu/Menu/components/CustomIngredientsSelectDishRow';
import { isDesktop } from 'app/utils/resolution';
import deleteIcon from './img/delete-btn.img.svg';
import AddMoreDishesButton from './components/AddMoreDishes/AddMoreDishes';
import {
    NEW_DISH_CARD_FRAGMENT, NEW_DISH_CARD_IMG, RECCURENT_CART_DATA,
    FETCH_JSON_CUSTOMIZATION_COMMENT,
} from './graphql/basketWithCustomization.graphql';
import { selectedPeriodVar } from '../../../apollo/reaction';

import './index.scss';

const NewDishImage = (props) => {
    const { cacheRef, openDishDetails } = props;

    const [isLoadingError, setIsLoadingError] = useState(false);

    const { data: { dish } } = useFragment({
        fragment: NEW_DISH_CARD_IMG,
        from: {
            __ref: cacheRef,
        },
    });

    /* SELECTORS */
    const imgPath = useMemo(() => {
        if (isLoadingError) return stubSrc;
        if (dish.verticalImage?.includes('stub-v')) return dish.previewMobileImage;
        return dish.verticalImage || dish.previewMobileImage;
    }, [isLoadingError, dish.previewMobileImage, dish.verticalImage]);

    return (
        <img
            role="none"
            src={imgPath}
            alt={cacheRef}
            onError={() => setIsLoadingError(true)}
            styleName="new-dish-img-root"
            onClick={openDishDetails}
        />
    );
};

function measureText(pText, pStyle) {
    let lDiv = document.createElement('div');

    document.body.appendChild(lDiv);

    if (pStyle != null) {
        lDiv.style = pStyle;
    }
    lDiv.style.position = 'absolute';
    lDiv.style.left = -1000;
    lDiv.style.top = -1000;

    lDiv.textContent = pText;

    const lResult = {
        width: lDiv.clientWidth,
        height: lDiv.clientHeight,
    };

    document.body.removeChild(lDiv);
    lDiv = null;

    return lResult;
}

// eslint-disable-next-line arrow-body-style
const splitTitleOnToLines = (size) => (arr) => {
    return arr.reduce((acc, word) => {
        const { width } = measureText([...acc.firstLine, word].join(' '), 'fake-title');

        if (
            width <= size && !acc.isFirstLineCompleted
        ) {
            return {
                ...acc,
                firstLine: [...acc.firstLine, word],
            };
        }

        return {
            ...acc,
            secondLine: [...acc.secondLine, word],
            isFirstLineCompleted: true,
        };
    }, {
        firstLine: [],
        secondLine: [],
        isFirstLineCompleted: false,
    });
};

const ClampedTitle = (props) => {
    const {
        titleContainerCurrent,
        fullDishTitle,
    } = props;

    const [firstLineText, setFirstLineText] = useState('');
    const [secondLineText, setSecondLineText] = useState('');

    const clampWholeTitle = useCallback(
        () => {
            const refRect = titleContainerCurrent?.getBoundingClientRect();
            const {
                firstLine,
                secondLine,
                // eslint-disable-next-line no-unsafe-optional-chaining
            } = splitTitleOnToLines((refRect?.width - 10) || 0)(fullDishTitle.split(' '));
            setFirstLineText(firstLine.join(' '));
            setSecondLineText(secondLine.join(' '));
        },
        [fullDishTitle, titleContainerCurrent],
    );


    useEffect(
        () => {
            clampWholeTitle();
            window.addEventListener('resize', clampWholeTitle);
            return () => {
                window.removeEventListener('resize', clampWholeTitle);
            };
        },
        [clampWholeTitle],
    );

    return (
        <>
            <div styleName="dish-title-first-line">
                {firstLineText}
            </div>
            <div styleName="dish-title-second-line">
                {secondLineText}
            </div>
        </>
    );
};

const NewDishRightSideComponent = (props) => {
    const {
        openPortionSelectDatail,
        cacheRef,
        openDishDetails,
        selectedDishId,
        setSelectedDishId,
        isCustomizationEnabled,
        mutateItemInCart,
        isCartUpdating,
    } = props;

    const titleContainerRef = useRef(null);
    const [titleContainerCurrent, setTitleContainerCurrent] = useState(null);

    useEffect(() => {
        setTitleContainerCurrent(titleContainerRef.current);
    }, [titleContainerRef]);

    const period = selectedPeriodVar();
    const { ingredientsList, isDishCardWithCustomSelect } = useDishCustoms(selectedDishId || '');


    const isCustomSelect = useMemo(
        () => isDishCardWithCustomSelect && isCustomizationEnabled,
        [isDishCardWithCustomSelect, isCustomizationEnabled]);

    /* DAC */
    useQuery(FETCH_JSON_CUSTOMIZATION_COMMENT, {
        context: {
            step: 'bakset:support:NewDishRightSideComponent',
        },
    });

    const {
        data: {
            portions, price, dish,
            discount_price, dish_id,
        },
    } = useFragment({
        fragment: NEW_DISH_CARD_FRAGMENT,
        from: {
            __ref: cacheRef,
        },
    });

    const { data: { isSubscriptionActivated } } = useFragment({
        fragment: RECCURENT_CART_DATA,
        from: {
            __ref: `DiscountConditions:${period}`,
        },
    });

    /* SELECTORS */
    const fullDishTitle = `${dish.title} ${dish.caption}`;

    const currentPrice = useMemo(
        () => (isSubscriptionActivated ? (discount_price / portions) : (price / portions)),
        [portions, price, isSubscriptionActivated, discount_price],
    );

    /* STYLES */

    const contentRoot = cn({
        'new-dish-content-root': true,
    });

    const newDishControls = cn({
        'new-dish-controls-root': true,
        'new-dish-controls-root.with_customization': isCustomizationEnabled,
    });

    const onNextIdSelected = (nextDishId) => {
        setSelectedDishId(nextDishId);
    };

    const onSubmitHandler = ({ onCompoleted }) => {
        if (selectedDishId === dish_id) {
            onCompoleted();
        } else {
            mutateItemInCart({
                onCompoleted,
                dishes: [
                    {
                        dish_id: selectedDishId,
                        portions,
                    },
                    {
                        dish_id,
                        portions: 0,
                    },
                ],
                optimistic: 'swipe',
            });
        }
    };

    return (
        <div styleName={contentRoot}>
            <div styleName={newDishControls}>
                <button
                    onClick={openDishDetails}
                    styleName="dish-control-title"
                    type="button"
                    ref={titleContainerRef}
                    id="dish-control-title-wrapper"
                >
                    <ClampedTitle
                        titleContainerCurrent={titleContainerCurrent}
                        fullDishTitle={fullDishTitle}
                    />
                </button>
                <button
                    styleName="dish-control-portion basket-with--dots"
                    onClick={openPortionSelectDatail}
                >
                    <div styleName="dish-control-protion__count">
                        {portions}
                    </div>
                    <div styleName="dish-control-protion__divider">
                        x
                    </div>
                    <div styleName="dish-control-protion__price">
                        {currentPrice}
                        <span
                            style={{ paddingLeft: 2, fontWeight: 500 }}
                            styleName="rubCharWrapper"
                        >
                            <Rub />
                        </span>
                    </div>
                </button>
                {isCustomSelect && (
                    <div styleName="dish-control-select-wrapper">
                        <CustomIngredientsSelectDishRow
                            environmentContext="basket"
                            selectedDishId={selectedDishId}
                            onNextIdSelected={onNextIdSelected}
                            id={selectedDishId}
                            ingredientsList={ingredientsList}
                            disabled={isCartUpdating}
                            onSubmitHandler={onSubmitHandler}
                        />

                    </div>
                )}
            </div>
        </div>
    );
};

const NewDishCard = (props) => {
    const {
        uiState,
        handleChangePortion,
        handleRemoveDish,
        onClickMobilePortions,
        cacheRef,
        openDishDetails,
        mutateItemInCart,
        isCartUpdating,
        isEditBasketBlock,
    } = props;

    const [transition, setTransition] = useState('default');

    const {
        data: {
            dish_id, dish, portions: portion,
        },
    } = useFragment({
        fragment: NEW_DISH_CARD_FRAGMENT,
        from: {
            __ref: cacheRef,
        },
    });
    const [selectedDishId, setSelectedDishId] = useState(dish_id);

    const changePortionCount = ({ value }) => {
        handleChangePortion({ id: dish_id, value });
    };

    const handleOpenDetails = useCallback(() => {
        openDishDetails(dish_id);
    }, [openDishDetails, dish_id]);

    const openPortionSelectDatail = () => {
        onClickMobilePortions({
            id: dish_id,
            portions: dish.portions,
            portion,
            weight: dish.weight,
            onChange: changePortionCount,
            portionAlias: null,
        });
    };

    const removeDishUIHandler = () => {
        setTransition('exiting');
        handleRemoveDish(dish_id);
    };

    const dishCardAnimationWrapper = cn({
        'new-dish-animation-wrapper': true,
        [transition]: true,
    });

    const dishCardRoot = cn({
        'new-dish-card-root': true,
    });

    const dishCardImageRow = cn({
        'new-dish-card-image-row': true,
    });

    const dishCardRightRow = cn({
        'new-dish-card-left-row': true,
    });

    const isCustomizationEnabled = !isEditBasketBlock;


    return (
        <div styleName={dishCardAnimationWrapper}>
            <div styleName={dishCardRoot}>
                {!isEditBasketBlock && (
                    <button
                        disabled={isCartUpdating}
                        styleName="new-dish-card-delete-btn"
                        onClick={removeDishUIHandler}
                    >
                        <img
                            src={deleteIcon}
                            alt="d-icon"
                        />
                    </button>
                )}
                <div styleName={dishCardImageRow}>
                    <NewDishImage
                        cacheRef={cacheRef}
                        openDishDetails={handleOpenDetails}
                    />
                </div>
                <div styleName={dishCardRightRow}>
                    <NewDishRightSideComponent
                        uiState={uiState}
                        openPortionSelectDatail={openPortionSelectDatail}
                        cacheRef={cacheRef}
                        openDishDetails={handleOpenDetails}
                        selectedDishId={selectedDishId}
                        setSelectedDishId={setSelectedDishId}
                        isCustomizationEnabled={isCustomizationEnabled}
                        mutateItemInCart={mutateItemInCart}
                        isCartUpdating={isCartUpdating}
                        isEditBasketBlock={isEditBasketBlock}
                    />
                </div>
            </div>
        </div>
    );
};

const BasketWithCustomization = (props) => {
    const {
        handleAddMoreButtonClick,
        handleChangePortion,
        onClickMobilePortions,
        handleRemoveDish,
        primetiveSection,
        mutateItemInCart,
        isCartUpdating,
    } = props;

    const { openDishDetails } = useDishDetails();

    /* SELECTORS */
    const isEditBasketBlock = useMemo(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const hideParams = urlParams.get('hide') || '';
        const param = hideParams || '';
        return param?.includes('edit_basket');
    }, []);

    const dishList = primetiveSection.map((cacheRef) => (
        <NewDishCard
            key={cacheRef}
            cacheRef={cacheRef}
            handleChangePortion={handleChangePortion}
            handleRemoveDish={handleRemoveDish}
            onClickMobilePortions={onClickMobilePortions}
            openDishDetails={openDishDetails}
            mutateItemInCart={mutateItemInCart}
            isCartUpdating={isCartUpdating}
            isEditBasketBlock={isEditBasketBlock}
        />
    ));

    const isMobileResolution = !isDesktop();

    return (
        <>
            <div styleName="new-dish-list">
                {dishList}
            </div>
            {isMobileResolution ? (
                <>
                    <div styleName="newUpsellDividerGrey" />
                    <div styleName="newUpsellDividerWhite" />
                </>
            ) : (
                <AddMoreDishesButton
                    handleAddMoreButtonClick={handleAddMoreButtonClick}
                />
            )}
        </>
    );
};

export default React.memo(BasketWithCustomization);
